import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useShopify } from '../../hooks';

import HamburgerMenu from './HamburgerMenu';
import Cart from '../../Components_Shopify/Cart';
import LineItem from '../../Components_Shopify/LineItem';

import './Navbar.css';

import Logo from '../../Assets/logo.png';
import CartIcon from '../../Assets/ShoppingCart.svg';
import FingerPrint from '../../Assets/fingerTrans.png';
import Hamburger from '../../Assets/Hamburger.svg';
import HamburgerClose from '../../Assets/Close.svg';

const NavBar = (props) => {
    // const { load } = props;
    const { closeCart, openCart, cartStatus } = useShopify();
    const [route, setRoute] = useState();
    const [hamburger, setHamburger] = useState();
    // const [load, setLoad] = useState(false);
    let navigate = useNavigate();
    let location = useLocation();
    const path = location.pathname;

    function handleOpen(e) {
		e.preventDefault()
		openCart()
	}

	function handleClose(e) {
		e.preventDefault()
		closeCart()
	}

    useEffect(() => {
        setRoute(path)
    },[location])

    return (
        <motion.div 
            className='nav-container'
            style={{ y: 0 }}
            // initial={{ y: -500 }}
            // animate={{ y: load ? 0 : -500, transition: { duration: 1 } }}
        >
            <div style={{ width: "30%", background: "red" }}>
                <img
                    src={Logo}
                    className='header-image' 
                    onClick={() => navigate('/')}
                    alt='Castro logo and psuedo home button'
                />
            </div>
            <div className='d-md-none d-none d-lg-block'>
                <ul className='mainMenu-ul'>
                    <li className='mainMenu-li'>
                        <h3 className='navbar-text' onClick={() => navigate('/')}>
                            Home
                            {route === '/' && <img src={FingerPrint} alt='fingerprint' className='fingerprint' />}
                        </h3>
                    </li>
                    <li className='mainMenu-li'>
                        <h3 className='navbar-text' onClick={() => navigate('/videos')}>
                            Videos
                            {route === '/videos' && <img src={FingerPrint} alt='fingerprint' className='fingerprint' />}
                        </h3>
                    </li>
                    <li className='mainMenu-li'>
                        <h3 className='navbar-text' onClick={() => navigate('/music')}>
                            Music
                            {route === '/music' && <img src={FingerPrint} alt='fingerprint' className='fingerprint' />}
                        </h3>
                    </li>
                    <li className='mainMenu-li'>
                        <h3 className='navbar-text' onClick={() => navigate('/shop')}>
                            Shop
                            {route === '/shop' && <img src={FingerPrint} alt='fingerprint' className='fingerprint' />}
                        </h3>
                    </li>
                    {/* <Cart /> */}
                    <li className='mainMenu-li'>
                        <img
                            src={CartIcon} 
                            style={{ height: "40px", marginTop: "22px", cursor: "pointer" }} 
                            onClick={(e) => cartStatus ? handleClose(e) : handleOpen(e)}
                        />
                    </li>
                </ul>
            </div>
            <div className='d-block d-lg-none' style={{ position: "relative", zIndex: "999" }}>
                <img 
                    src={hamburger ? HamburgerClose : Hamburger} 
                    style={{ 
                        height: "30px", 
                        marginTop: "35px", 
                        cursor: "pointer",
                        float: "right",
                        marginRight: "15px",
                    }} 
                    alt="hamburger menu"
                    onClick={() => setHamburger(!hamburger)}
                />
                <img
                    src={CartIcon} 
                    style={{ height: "35px", marginTop: "30px", marginRight: "30px", cursor: "pointer", float: "right" }} 
                    onClick={(e) => cartStatus ? handleClose(e) : handleOpen(e)}
                />
            </div>
            <HamburgerMenu hamburger={hamburger} />
        </motion.div>
    )
}

export default NavBar;