import React, { useEffect, useState } from "react";
import { Container, Col, Row } from 'react-bootstrap';
import LineItem from "./LineItem";
import { useShopify } from "../hooks";

// import CartIcon from '../Assets/ShoppingCart.svg';
import Logo from '../Assets/logo.png';
import Close from '../Assets/Close.svg';

export default (props) => {
	const mobile = window.innerWidth < 600
	const {
		cartStatus,
		closeCart,
		checkoutState,
		setCount,
	} = useShopify()

	function handleClose(e) {
		e.preventDefault()
		closeCart()
	}

	function openCheckout(e) {
		e.preventDefault()
		window.location.replace(checkoutState.webUrl)
	}

	useEffect(() => {
		function getCount() {
			let lineItems =
				checkoutState.lineItems && checkoutState.lineItems.length > 0
					? checkoutState.lineItems
					: []
			let count = 0
			lineItems.forEach((item) => {
				count += item.quantity
				return count
			})
			setCount(count)
		}
		getCount()
	}, [cartStatus, checkoutState])

	return (
			<Container fluid className={`Cart ${cartStatus ? "Cart--open" : ""}`} >
				<h2 className="cart-header">Cart</h2>
				<img 
                    src={Close} 
                    style={{ 
						position: "fixed", 
						right: "30px", 
						top: "30px",
						height: "30px",
						cursor: "pointer",
						zIndex: "99",
                    }} 
                    alt="hamburger menu"
                    onClick={(e) => handleClose(e)}
                />
				<Row style={{ height: mobile ? "40vh" : "70vh" }}>
					<Col lg={7} style={{ height: "100%" }}>
						<ul className="Cart__line-items">
							<LineItem />
						</ul>
					</Col>
					<Col 
						lg={5} 
						style={{ 
							height: "90%", 
							borderLeft: mobile ? "" : "2px solid white", 
							position: "absolute",
							bottom: "5%",
							right: 0,
							zIndex: "9",
						}}
					>
						<div
							style={{
								position: "absolute",
								top: "20px",
								right: 0,
								width: "100%",
							}}
							className='d-md-none d-none d-lg-block'
						>
							<img
								src={Logo}
								style={{ width: "50%", marginLeft: "25%" }}
								alt='Castro logo and psuedo home button'
							/>
						</div>
						<div style={{ position: "absolute", bottom: 0, right: "0", width: "100%" }}>
						<footer className="Cart__footer">
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Subtotal</div>
								<div className="Cart-info__pricing">
									<span className="pricing">$ {checkoutState.subtotalPrice && checkoutState.subtotalPrice.amount}</span>
								</div>
							</div>
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Taxes</div>
								<div className="Cart-info__pricing">
									<span className="pricing">$ {checkoutState.subtotalPrice && checkoutState.totalTax.amount}</span>
								</div>
							</div>
							<div className="Cart-info clearfix">
								<div className="Cart-info__total Cart-info__small">Total</div>
								<div className="Cart-info__pricing">
									<span className="pricing">$ {checkoutState.subtotalPrice && checkoutState.totalPrice.amount}</span>
								</div>
							</div>
							<h2
								className="Cart__checkout"
								onClick={(e) => openCheckout(e)}
							>
								Checkout
							</h2>
						</footer>
						</div>
					</Col>
				</Row>
			</Container>
	)
}