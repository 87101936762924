import React from 'react';
import { motion } from 'framer-motion';

import Splat1 from '../Assets/splatRed@3xFinal.png';
import Splat2 from '../Assets/splatRed2@3xFinal.png';

const TransitionCurtain = (props) => {
    const { title } = props
    return (
        <motion.div
            animate={{ y: "100vh", transition: {duration: 1, delay: 1} }}
            style={{ 
                position: "fixed", 
                height: "100vh", 
                width: "100vw", 
                background: "red", 
                zIndex: "999", 
                textAlign: 'center',
                top: "0"
            }}
        >
            <img src={Splat1} className="splat1" style={{ transform: "scale(1.1)" }} alt="decorative blood splat" />
            <img src={Splat2} className="splat2" style={{ transform: "scale(0.9) rotate(180deg)" }} alt="decorative blood splat" />
            <h2 className='transition-text'>{title}</h2>
        </motion.div>
    )
}

export default TransitionCurtain;