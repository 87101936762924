import * as THREE from "three";
import React, { useRef, useState, useEffect } from "react";
import { Canvas, createPortal, useFrame } from "@react-three/fiber";
import {
  useFBO,
  PerspectiveCamera,
  useGLTF,
} from "@react-three/drei";
import { useSpring } from '@react-spring/core';
import { a } from '@react-spring/three';
// import { animated } from 'react-spring';

import Candle from '../Components3D/Candle';
import Controls from '../Components3D/Controls';
import TransitionCurtain from './TransitionCurtain';
import Navbar from './Navigation/NavBar';

function MagicCard({ children, ...props }) {
  const cam = useRef();
  const fbo = useFBO({ stencilBuffer: true });
  const mobile = window.innerWidth < 600
  const { nodes, materials } = useGLTF("/CardKyle.glb");
  const [scene] = useState(() => new THREE.Scene());

  const rotation = [0, 7, 0]
  const scale = [0, 0, 0]

  useEffect(() => {
    set({ 
      rotation: [0, 0, 0],
      scale: [1, 1, 1]
    })
  })

  const [spring, set] = useSpring(() => ({
    scale: [...scale],
    rotation: [...rotation],
    config: { 
      mass: 10, 
      friction: 60, 
      tension: 100
    },
  }))
  
  scene.background = new THREE.Color("black");
  useFrame((state) => {
    cam.current.matrixWorldInverse.copy(state.camera.matrixWorldInverse);
    state.gl.setRenderTarget(fbo);
    state.gl.render(scene, cam.current);
    state.gl.setRenderTarget(null);
  });

  return (
    <>
      <a.group {...spring} >
          <a.group 
            {...props} 
            dispose={null} 
            position={[0, 0, 0]}
            scale={mobile ? 0.013 : 0.015}
          >
            <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes["card-border"].geometry}
              >
                <meshStandardMaterial attach="material" color="red" roughness={0.1} metalness={0.1}/>
              </mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes["card-front"].geometry}
                material={materials.front}
              >
                <meshStandardMaterial attach="material" color="red" roughness={0.1} metalness={0.1}/>
              </mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes["card-back"].geometry}
                material={materials.back}
              />
            </group>
          </a.group>

      <mesh {...props} position={[0, 0, 0.01]}>
        <planeGeometry args={mobile ? [2.5, 5.0] : [2.8, 5.7]} />
        <meshPhysicalMaterial map={fbo.texture} />
      </mesh>

      <PerspectiveCamera
        manual
        ref={cam}
        fov={75}
        aspect={2.5 / 5}
        onUpdate={(c) => c.updateProjectionMatrix()}
      />
      {createPortal(children, scene)}
      </a.group>
    </>
  );
}

function FrontSceneLights() {
  return (
  <>
    <pointLight
      args={["white", 7, 3]}
      position={[0, 0, 2]}
      rotation={[0, 0, 0]}
      scale={[2, 2, 2]}
      intensity={3}
    />
    <pointLight
      args={["red", 7, 3]}
      position={[0, 1.5, -1.5]}
      rotation={[0, 0, 0]}
      scale={[2, 2, 2]}
      intensity={3}
    />
  </>
  );
}

function Home() {
  const [load, setLoad] = useState();

  useEffect(() => {
    setLoad(true)
  },[])

  return (
    <div className="App">
      <TransitionCurtain title={"HOME"} />
      <Navbar />
      <Canvas style={{ height: "100vh", width: "100vw" }} >
        <ambientLight intensity={1} />
        <Controls />
        <MagicCard>
          <FrontSceneLights />
          <ambientLight intensity={0.6} />
          <Candle />
        </MagicCard>
      </Canvas>
    </div>
  );
}

export default Home;