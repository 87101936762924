import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const HamburgerMenu = (props) => {
    const { hamburger } = props

    return (
        <motion.div 
            initial={false}
            animate={{ x: hamburger ? 0 : "100%", transition: { duration: 1 } }}
            className='nav-container-mobile'
        >
                <nav style={{ paddingTop: "20vh", textAlign: "center" }}> 
                    <li className="menu-list-item menu-list-item--right">
                        <Link to="/" style={{ textDecoration: "none" }}>
                        	<h5 className='menu-text-h1' >
                                    HOME
                            </h5>
                        </Link>
                    </li>
                    <li className="menu-list-item menu-list-item--right" >
                        <Link to="/music" style={{ textDecoration: "none" }}>
                        	<h5 className='menu-text-h1' >
                                    MUSIC
                            </h5>  
                        </Link>
                    </li>
                    <li className="menu-list-item menu-list-item--right">
                        <Link to="/videos" style={{ textDecoration: "none" }}>
                        	<h5 className='menu-text-h1'>
                                    VIDEOS
                            </h5> 
                        </Link>
                    </li>
                    <li className="menu-list-item menu-list-item--right">
                        <Link to="/shop" style={{ textDecoration: "none" }}>
                        	<h5 className='menu-text-h1'>
                                    SHOP
                            </h5> 
                        </Link>
                    </li>
                </nav> 
        </motion.div>
    )
}

export default HamburgerMenu;