import React, { useEffect, useState, useRef } from "react";
import Lottie from "lottie-react";
import Loading from "../../Assets/loadingAnimation.json";

export default function GoBackLottie() {
    const ref = useRef();

    useEffect(() => {
        ref.current.play();
    },[])
  
    return (
      <div 
        style={{ 
            width: '100vw', 
            textAlign: 'center',
            height: "100vh",
        }}
    >
        <Lottie 
            style={{
                marginTop: "20vh",
                height: '60vh',
                cursor: 'pointer',
                display: 'inline-block',
                left: 0,
                width: "100%",
            }}
            lottieRef={ref} 
            animationData={Loading}
            loop={true}
            autoplay={false}
        />
      </div>
    );
  }