import React from 'react';

import TransitionCurtain from './TransitionCurtain';
import VideoBackground from '../Assets/videoBackground.png';
import Navbar from './Navigation/NavBar'

const Videos = () => {
    const mobile = window.innerWidth < 600

    return (
        <div style={{ overflow: "none" }}>
            <Navbar />
            <TransitionCurtain title={"VIDEO"} />
            <div 
                className='backgroundLayer'
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    backgroundImage: `url(${VideoBackground})`,
                }}
            >
                <iframe
                    style={{ 
                        marginTop: "30vh",                        
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: "12pt",
                        width: mobile ? "90%" : "40%",
                        left: mobile ? "5%" : "30%",
                        background: "white",
                    }} 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/mROo2UmDbSU?si=u0Z3FrtGjO9ErCnr" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
            <div 
                className='backgroundLayer'
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    backgroundImage: `url(${VideoBackground})`,
                }}
            >
                <iframe
                    style={{ 
                        marginTop: "30vh",                        
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: "12pt",
                        width: mobile ? "90%" : "40%",
                        left: mobile ? "5%" : "30%",
                        background: "white",
                    }} 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/oClhiyFZM7s?si=1ME2fNClL5cepDTU" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
            <div 
                className='backgroundLayer'
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    backgroundImage: `url(${VideoBackground})`,
                }}
            >
                <iframe
                    style={{ 
                        marginTop: "30vh",                        
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: "12pt",
                        width: mobile ? "90%" : "40%",
                        left: mobile ? "5%" : "30%",
                        background: "white",
                    }} 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/sbC_yy1nTVc?si=ZUUuSAZ53TcLx9kU" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
            <div 
                className='backgroundLayer'
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    backgroundImage: `url(${VideoBackground})`,
                }}
            >
                <iframe
                    style={{ 
                        marginTop: "30vh",                        
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: "12pt",
                        width: mobile ? "90%" : "40%",
                        left: mobile ? "5%" : "30%",
                        background: "white",
                    }} 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/IGAfugT2zDQ?si=UQnjOUa_nP-eD7js" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
            <div 
                className='backgroundLayer'
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    backgroundImage: `url(${VideoBackground})`,
                }}
            >
                <iframe
                    style={{ 
                        marginTop: "30vh",                        
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: "12pt",
                        width: mobile ? "90%" : "40%",
                        left: mobile ? "5%" : "30%",
                        background: "white",
                    }} 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/TP6N2AcGrvg?si=DFPuW3mkkGMkNYuH" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
            <div 
                className='backgroundLayer'
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    backgroundImage: `url(${VideoBackground})`,
                }}
            >
                <iframe
                    style={{ 
                        marginTop: "30vh",                        
                        cursor: 'pointer',
                        display: 'inline-block',
                        borderRadius: "12pt",
                        width: mobile ? "90%" : "40%",
                        left: mobile ? "5%" : "30%",
                        background: "white",
                    }} 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/P9jXD1tuSFA?si=HC0-tyNzhxjxeVU6" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen
                >
                </iframe>
            </div>
        </div>
    )
}

export default Videos;