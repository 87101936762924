import React from 'react';
import { useSpring, animated } from 'react-spring';

import TransitionCurtain from './TransitionCurtain';
import Navbar from './Navigation/NavBar';
// import Splat1 from '../Assets/splatRed@3xFinal.png';
// import Splat2 from '../Assets/splatRed2@3xFinal.png';

const Music = () => {
    const calc = (x, y) => [-(y - window.innerHeight / 2) / 90, (x - window.innerWidth / 2) / 90, 1.01]
    const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
    const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))

    return (
        <div>
            <Navbar />
            <TransitionCurtain title={"MUSIC"} />
            <animated.div 
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ 
                    width: '100vw', 
                    textAlign: 'center',
                    height: "100vh",
                    transform: props.xys.to(trans),
                }}
            >
                <iframe 
                    style={{ 
                        marginTop: "30vh",
                        cursor: 'pointer',
                        display: 'inline-block',
                        width: "80%",
                        borderRadius: "12pt",
                    }} 
                    src="https://open.spotify.com/embed/track/5LQsl3uZfVXdv6oZSdY57l?utm_source=generator"  
                    frameBorder="0" 
                    height="500"
                    allowFullScreen="" 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
                <iframe 
                    style={{ 
                        marginTop: "30vh",
                        cursor: 'pointer',
                        display: 'inline-block',
                        width: "80%",
                        borderRadius: "12pt",
                    }} 
                    src="https://open.spotify.com/embed/track/6yIajPJAVRdRoG4xJ7m1WT?utm_source=generator&theme=0"  
                    frameBorder="0" 
                    height="500"
                    allowFullScreen="" 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
                <iframe 
                    style={{ 
                        marginTop: "30vh",
                        cursor: 'pointer',
                        display: 'inline-block',
                        width: "80%",
                        borderRadius: "12pt",
                    }} 
                    src="https://open.spotify.com/embed/track/2CSUpKbvwm1kWfjuNjKAyM?utm_source=generator"  
                    frameBorder="0" 
                    height="500"
                    allowFullScreen="" 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
                <iframe 
                    style={{ 
                        marginTop: "30vh",
                        cursor: 'pointer',
                        display: 'inline-block',
                        width: "80%",
                        borderRadius: "12pt",
                    }} 
                    src="https://open.spotify.com/embed/track/6qBAhW4zN4l7BzjkfT8bqD?utm_source=generator"  
                    frameBorder="0" 
                    height="500"
                    allowFullScreen="" 
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                    loading="lazy">
                </iframe>
                {/* <img src={Splat1} className="splat1" />
                <img src={Splat2} className="splat2" /> */}
            </animated.div>
        </div>
    )
}

export default Music