/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Candle({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Candle.glb')
  const { actions } = useAnimations(animations, group)

  useEffect(() => {
    actions.animation_0.play()
  })

  return (
    <group ref={group} {...props} scale={0.01} dispose={null}  position={[0, 0.5, -3.2]}>
      <group>
        <group name="flames">
          <group name="flames_1" position={[125.18, 156.58, 24.85]} scale={[1, 1.03, 1]}>
            <mesh name="flames_2" geometry={nodes.flames_2.geometry} material={materials.flame} />
          </group>
          <group name="flames_3" position={[51, 97.1, -0.31]} scale={[1, 0.95, 1]}>
            <mesh name="flames_4" geometry={nodes.flames_4.geometry} material={materials.flame} />
          </group>
          <group name="flames_5" position={[-24.11, 125.68, 47.26]} scale={[1, 1.05, 1]}>
            <mesh name="flames_6" geometry={nodes.flames_6.geometry} material={materials.flame} />
          </group>
          <group name="flames_7" position={[-101.97, 264.13, 12.88]} scale={[1, 1.08, 1]}>
            <mesh name="flames_8" geometry={nodes.flames_8.geometry} material={materials.flame} />
          </group>
          <group name="flames_9" position={[-11.33, 176.46, -68.01]} scale={[1, 0.94, 1]}>
            <mesh name="flames_10" geometry={nodes.flames_10.geometry} material={materials.flame} />
          </group>
          <group name="flames_11" position={[93.9, 286.55, -64.26]} scale={[1, 1.07, 1]}>
            <mesh name="flames_12" geometry={nodes.flames_12.geometry} material={materials.flame} />
          </group>
        </group>
        {/* <PerspectiveCamera name="Camera" makeDefault={false} far={10000000000} near={0.01} fov={7.32} position={[0, -51.25, 7604.78]} rotation={[-0.02, 0, 0]} /> */}
        <mesh name="candle" geometry={nodes.candle.geometry} material={materials.candle} />
        <mesh name="BG" geometry={nodes.BG.geometry} material={materials.bg}>
          {/* <meshStandardMaterial attach="material" color="#0e0e0e" roughness={1} metalness={0}/> */}
          <meshStandardMaterial attach="material" color="black" roughness={1} metalness={0}/>
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/Candle.glb')