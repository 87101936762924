import React from "react";
import { useShopify } from "../hooks";

import Close from '../Assets/Close.svg';

export default (props) => {
	const { checkoutState, updateQuantity, removeLineItem, products } = useShopify()

	function decrementQuantity(lineItemId, lineItemQuantity, e) {
		e.preventDefault()
		const checkoutId = checkoutState.id
		const updatedQuantity = lineItemQuantity - 1
		updateQuantity(lineItemId, updatedQuantity, checkoutId)
	}

	function incrementQuantity(lineItemId, lineItemQuantity, e) {
		e.preventDefault()
		const checkoutId = checkoutState.id
		const updatedQuantity = lineItemQuantity + 1
		updateQuantity(lineItemId, updatedQuantity, checkoutId)
	}

	function deleteLineItem(lineItemId, e) {
		e.preventDefault()
		const checkoutId = checkoutState.id
		removeLineItem(checkoutId, lineItemId)
	}

	return (
		<li className="Line-item">
			{checkoutState.lineItems &&
				checkoutState.lineItems.map((lineItem, i) => {
					return (
						<div key={`${lineItem.title}` + i} style={{ height: "100px", marginTop: "10px" }}>
							{lineItem.variant.image ? (
								<div className="Line-item__img" style={{ backgroundImage: `url(${lineItem.variant.image.src})` }}>
								</div>
							) : null }
							<div className="Line-item__content">
								<div className="Line-item__content-row">
									<div className="Line-item__variant-title">
										{lineItem.variant.title}
									</div>
									<div>
										<h1 className="Line-item__title">{lineItem.title}</h1>
									</div>
								</div>
								<div className="Line-item__content-row" style={{ bottom: 0, position: "absolute", left: 0 }}>
									<div className="Line-item__quantity-container">
										<button
											className="Line-item__quantity-update"
											onClick={(e) =>
												decrementQuantity(lineItem.id, lineItem.quantity, e)
											}
										>
											–
										</button>
										<span className="Line-item__quantity">
											{lineItem.quantity}
										</span>
										<button
											className="Line-item__quantity-update"
											onClick={(e) => {
												incrementQuantity(lineItem.id, lineItem.quantity, e)
											}}
										>
											+
										</button>
									</div>
									<button
										className="Line-item__remove"
										onClick={(e) => deleteLineItem(lineItem.id, e)}
									>
										<img 
											src={Close} 
											style={{ 
												height: "15px",
												cursor: "pointer"
											}} 
											alt="hamburger menu"
										/>
									</button>
									<span className="Line-item__price">
										$ {(lineItem.quantity * lineItem.variant.price.amount).toFixed(2)}
									</span>
								</div>
							</div>
						</div>
					)
				})}
		</li>
	)
}